import React from "react"
import Avatar from "../ui/Avatar"
import Container from "../ui/Container"
import styled from 'styled-components'
import quoteImage from '../../images/quote-blue.png'

const Header = styled.div`
    padding: 50px 0 0;
    text-align: center;
    & ${Avatar} {
        width: 200px;
        height: 200px;
        background: #D4DBF5;
    }
`
const Name = styled.div`
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    margin: 10px auto 0;
`
const Title = styled.div`
    font-size: 14px;
    line-height: 17px;
    color: #250044;
    margin: 10px auto 0;
`
const Description = styled.div`
    margin: 50px auto 0;
`
const About = styled.div`
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.43px;
    text-transform: uppercase;
    text-align: left;
`
const Text = styled.div`
    margin: 20px auto 0;
    font-size: 16px;
    line-height: 30px;
    color: #250044;
    text-align: left;
`
const Quote = styled.div`
    margin: 60px auto 0;
    text-align: center;
    font-weight: 600;
`
const QuoteCharacter = styled.img`
    width: 97px;
    height: 72px;
`
const QuoteText = styled.div`
    font-style: italic;
    font-weight: 600;
    font-size: 18px;
    line-height: 40px;
    letter-spacing: -1px;
    color: #250044;
    margin: 20px auto 0;
`
const Wrapper = styled.div`
    padding: 0 0 200px;
    text-align: center;
    
    @media (min-width: 834px) {
        text-align: left;

        ${Quote} {
            display: flex;
            width: 70%;
        }
    }
`

export default function Bio({ name, title, profile, bio, quote, education }) {

    return <Wrapper>
        <Container>
            <Header>
                <Avatar src={profile} />
                <Name>{name}</Name>
                <Title dangerouslySetInnerHTML={{ __html: title }} />
            </Header>
            <Description>
                <About>ABOUT {name}</About>
                <Text dangerouslySetInnerHTML={{ __html: bio }} />
            </Description>
            <Quote>
                <QuoteCharacter src={quoteImage} />
                <QuoteText dangerouslySetInnerHTML={{ __html: quote }} />
            </Quote>
        </Container>
    </Wrapper>
}
