import React from "react"
import Layout from "../components/Layout"
import SEO from "../components/seo"
import Bio from "../components/sections/Bio"
import { edgeToBio } from '../lib/utils'
import { graphql } from 'gatsby'

const BioPage = ({ data }) => {

  const bio = edgeToBio({ edge: data.provider.edges[0] })

  return <Layout>
    <SEO title={bio.name} />
    <Bio {...bio} />
  </Layout>
}

export const query = graphql`
  query BioPageQuery($id: String) {
    provider: allPrismicProvider(filter: {id: {eq: $id}}) {
      edges {
        node {
          data{
            name{
              text
            }
            title {
              text
            }
            profile {
              localFile {
                childImageSharp {
                  fixed {
                    src
                  }
                }
              }
            }
            bio {
              html
            }
            quote {
              html
            }
          }
          uid
        }
      }
    }
  }`
export default BioPage
